import {
  get,
  post,
  update,
} from '../helpers'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        get('/status-pekerjaan', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        get(`/status-pekerjaan/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        post('/status-pekerjaan', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateData(ctx, userData) {
      return new Promise((resolve, reject) => {
        update('/status-pekerjaan', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
